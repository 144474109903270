import { produce } from 'immer'
import { type ActionType, getType } from 'typesafe-actions'

import { fetchCleanByIdAction, fetchCleansAction } from '../cleans/actions'
import { type HousekeepersState } from './housekeepers.types'
import { emptyNormalizedHousekeepersData } from './housekeepers.utils'

const initialState: HousekeepersState = {
  data: {},
}

const actions = {
  fetchCleanByIdAction,
  fetchCleansAction,
}

type Actions = ActionType<typeof actions>

export const housekeepersReducer = (
  state = initialState,
  action: Actions,
): HousekeepersState =>
  produce(state, (draft: HousekeepersState) => {
    switch (action.type) {
      case getType(fetchCleanByIdAction.success):
      case getType(fetchCleansAction.success): {
        const normalized =
          action?.payload?.normalized || emptyNormalizedHousekeepersData

        draft.data = { ...state.data, ...normalized.housekeeper }
      }
    }
  })
