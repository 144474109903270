import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Loader } from 'packages/common'
import { IconName } from 'packages/iconic'

import { useI18n, Slugs } from 'app/i18n'
import { type GwLocalHost } from 'app/store/gwLocalhosts'
import { type GwUnitStaffer } from 'app/store/gwUnitStaffers'

import { UnitSelectButton } from '../../core'
import { useUnitPickerModal } from '../../core/hooks/useUnitPickerModal/useUnitPickerModal'
import { NotificationFormContainer } from '../NotificationForm/NotificationForm.container'
import {
  ContactInfoIcon,
  ContactInfoIconBackground,
} from '../SupportPage/SupportPage.styles'
import { AccessSection } from './AccessSection'
import { getStafferByType } from './MyUnitsPage.helpers'
import * as St from './MyUnitsPage.styles'

export enum MyUnitsPageTestIds {
  container = 'MyUnitsPage__container',
}

export interface MyUnitsPageProps {
  gwLocalHosts: GwLocalHost[]
  gwUnitStaffers: GwUnitStaffer[]
  loading: boolean
  onDelete: () => void
}

const useTranslations = () => {
  const { t } = useI18n()

  return {
    access: t(Slugs.access),
    accessBody: t(Slugs.accessBody),
    addAccess: t(Slugs.addAccess),
    addAccessBody: t(Slugs.addAccessBody),
    localHost: t(Slugs.localHost),
    myHomes: t(Slugs.myHomes),
    noneAdded: t(Slugs.noneAdded),
    notifications: t(Slugs.notifications),
    notificationsBody: t(Slugs.notificationsBody),
    primaryHousekeeper: t(Slugs.primaryHousekeeper),
    primaryHousekeeperBody: t(Slugs.primaryHousekeeperBody),
  }
}

export const MyUnitsPage: React.FC<MyUnitsPageProps> = React.memo(
  ({ gwUnitStaffers, gwLocalHosts, loading, onDelete }) => {
    const navigate = useNavigate()
    const strings = useTranslations()

    const primaryHousekeeperStaffer = getStafferByType(
      gwUnitStaffers,
      'primary_housekeeper',
    )
    const localHostStaffer = getStafferByType(gwUnitStaffers, 'cx_contact')

    const [isEditingPrimaryHousekeeper, setIsEditingPrimaryHousekeeper] =
      React.useState(false)
    const [isEditingCxContact, setIsEditingCxContact] = React.useState(false)

    const { selectedUnit, showUnitPickerModal } = useUnitPickerModal()

    return (
      <section data-testid={MyUnitsPageTestIds.container}>
        <St.HeadingContainer>
          <St.HeadingIconContainer
            onClick={() => {
              navigate('/settings')
            }}
          >
            <St.HeadingIcon icon={IconName.leftArrow} size={16} />
          </St.HeadingIconContainer>
          <St.Heading>{strings.myHomes}</St.Heading>
        </St.HeadingContainer>

        <St.ButtonWrapper>
          <UnitSelectButton onPress={showUnitPickerModal}>
            {selectedUnit?.name}
          </UnitSelectButton>
        </St.ButtonWrapper>

        <AccessSection gwLocalHosts={gwLocalHosts} loading={loading} />
        <St.Divider />

        <St.NotificationsSection>
          <St.SectionHeading id="notifications">
            {strings.notifications}
          </St.SectionHeading>

          <St.SectionSubHeading>{strings.localHost}</St.SectionSubHeading>
          <St.SectionBody>{strings.notificationsBody}</St.SectionBody>

          {isEditingCxContact ? (
            <NotificationFormContainer
              gwUnitStaffer={localHostStaffer}
              onCancel={() => {
                setIsEditingCxContact(false)
              }}
              onSuccess={() => {
                setIsEditingCxContact(false)
              }}
              stafferType="cx_contact"
            />
          ) : (
            <>
              <St.StafferNameHeader>
                <St.Name>{localHostStaffer?.name || strings.noneAdded}</St.Name>
                <St.StafferEditOptions>
                  <St.IconButton
                    onClick={() => {
                      setIsEditingCxContact(prevState => !prevState)
                    }}
                  >
                    <St.IconBackground>
                      <St.Icon icon={IconName.edit} size={16} />
                    </St.IconBackground>
                  </St.IconButton>
                </St.StafferEditOptions>
              </St.StafferNameHeader>

              <St.ContactInfoContainer>
                <ContactInfoIconBackground>
                  <ContactInfoIcon icon={IconName.phone} size={16} />
                </ContactInfoIconBackground>
                <St.ContactInfo>
                  {localHostStaffer?.phone || strings.noneAdded}
                </St.ContactInfo>
              </St.ContactInfoContainer>

              <St.ContactInfoContainer>
                <ContactInfoIconBackground>
                  <ContactInfoIcon icon={IconName.mail} size={16} />
                </ContactInfoIconBackground>
                <St.ContactInfo>
                  {localHostStaffer?.email || strings.noneAdded}
                </St.ContactInfo>
              </St.ContactInfoContainer>
            </>
          )}

          <St.SectionSubHeading>
            {strings.primaryHousekeeper}
          </St.SectionSubHeading>

          <St.SectionBody>{strings.primaryHousekeeperBody}</St.SectionBody>
          {isEditingPrimaryHousekeeper ? (
            <NotificationFormContainer
              onCancel={() => {
                setIsEditingPrimaryHousekeeper(false)
              }}
              onSuccess={() => {
                setIsEditingPrimaryHousekeeper(false)
              }}
              gwUnitStaffer={primaryHousekeeperStaffer}
              stafferType="primary_housekeeper"
            />
          ) : (
            <St.PrimaryHkSection>
              <>
                <St.StafferNameHeader>
                  <St.Name>
                    {primaryHousekeeperStaffer?.name || strings.noneAdded}
                  </St.Name>
                  <St.StafferEditOptions>
                    <St.IconButton
                      onClick={() => {
                        setIsEditingPrimaryHousekeeper(prevState => !prevState)
                      }}
                    >
                      <St.IconBackground>
                        <St.Icon icon={IconName.edit} size={16} />
                      </St.IconBackground>
                    </St.IconButton>
                    <St.IconButton
                      onClick={onDelete}
                      disabled={!primaryHousekeeperStaffer}
                    >
                      <St.IconBackground>
                        <St.DeleteIcon
                          isDisabled={!primaryHousekeeperStaffer}
                          icon={IconName.trash}
                          size={18}
                        />
                      </St.IconBackground>
                    </St.IconButton>
                  </St.StafferEditOptions>
                </St.StafferNameHeader>
                <St.ContactInfoContainer>
                  <ContactInfoIconBackground>
                    <ContactInfoIcon icon={IconName.phone} size={16} />
                  </ContactInfoIconBackground>
                  <St.ContactInfo>
                    {primaryHousekeeperStaffer?.phone || strings.noneAdded}
                  </St.ContactInfo>
                </St.ContactInfoContainer>
                <St.ContactInfoContainer>
                  <ContactInfoIconBackground>
                    <ContactInfoIcon icon={IconName.mail} size={16} />
                  </ContactInfoIconBackground>
                  <St.ContactInfo>
                    {primaryHousekeeperStaffer?.email || strings.noneAdded}
                  </St.ContactInfo>
                </St.ContactInfoContainer>
                {loading && <Loader />}
              </>
            </St.PrimaryHkSection>
          )}
        </St.NotificationsSection>
      </section>
    )
  },
)
