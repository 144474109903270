import { produce } from 'immer'
import { type ActionType, getType } from 'typesafe-actions'

import {
  fetchCleanByIdAction,
  fetchCleansAction,
  fetchSharedCleanByIdAction,
} from '../cleans/actions'
import { fetchTicketsAction, fetchTicketByIdAction } from '../tickets/actions'
import { type LockboxesState } from './lockboxes.types'
import { emptyNormalizedLockboxesData } from './lockboxes.utils'

const initialState: LockboxesState = {
  data: {},
  error: undefined,
}

const actions = {
  fetchCleanByIdAction,
  fetchCleansAction,
  fetchSharedCleanByIdAction,
  fetchTicketByIdAction,
  fetchTicketsAction,
}

type LockboxActionsTypes = ActionType<typeof actions>

export const lockboxesReducer = (
  state = initialState,
  action: LockboxActionsTypes,
): LockboxesState =>
  produce(state, (draft: LockboxesState) => {
    switch (action.type) {
      case getType(fetchTicketByIdAction.success):
      case getType(fetchCleanByIdAction.success):
      case getType(fetchCleansAction.success):
      case getType(fetchSharedCleanByIdAction.success):
      case getType(fetchTicketsAction.success): {
        const normalized =
          action?.payload?.normalized || emptyNormalizedLockboxesData

        draft.data = {
          ...state.data,
          ...normalized.lockBox,
        }

        draft.error = undefined
      }
    }
  })
