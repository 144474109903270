/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export const CACHE_KEY_ACTIVE_USER = 'activeUser'
export const CACHE_KEY_AUTH_USER = 'authUser'

const getAuthUser = () => {
  const userInLS = localStorage.getItem(CACHE_KEY_AUTH_USER)
  return userInLS ? JSON.parse(userInLS) : undefined
}

export const getActiveUser = () => {
  const userInLS = localStorage.getItem(CACHE_KEY_ACTIVE_USER)
  return userInLS ? JSON.parse(userInLS) : undefined
}

export const getActiveUserId = (): string | undefined => {
  const user = getActiveUser()
  return user ? user?.id : undefined
}

export const getAuthUserId = (): string | undefined => {
  const user = getAuthUser()
  return user ? user?.id : undefined
}

export const setAuthUser = (user): void => {
  localStorage.setItem(CACHE_KEY_AUTH_USER, JSON.stringify(user))
}

export const setActiveUser = (user): void => {
  localStorage.setItem(CACHE_KEY_ACTIVE_USER, JSON.stringify(user))
}
