import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { SvgIcon } from 'packages/iconic'
import { colors, centerWithFlex } from 'packages/styles'

import { gwText } from 'app/styles'

export {
  ButtonWrapper,
  HeadingContainer,
  HeadingIconContainer,
  HeadingIcon,
  Heading,
} from '../Settings.styles'

export const DeleteIcon = styled(SvgIcon)<{ isDisabled: boolean }>`
  bottom: 1px;
  color: ${({ isDisabled }) =>
    isDisabled ? `${colors.midnight30}` : `${colors.alert}`};
  left: 1px;
  position: relative;
`

export const Icon = styled(SvgIcon)`
  bottom: 1px;
  color: ${colors.midnight90};
  left: 1px;
  position: relative;
`

export const IconBackground = styled.div`
  ${centerWithFlex};
  background-color: ${colors.midnight10};
  border-radius: 50%;
  height: 32px;
  width: 32px;
`

export const IconButton = styled.button`
  ${centerWithFlex};
  background-color: ${colors.midnight10};
  height: 32px;
  width: 32px;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
`
const sectionBorder = css`
  border-top: 1px solid ${colors.midnight20};
  margin: 24px 24px 0px 24px;
`

export const Divider = styled.div`
  ${sectionBorder}
`
export const NotificationsSection = styled.section`
  margin: 24px 24px 0px 24px;
`
export const PrimaryHkSection = styled.section`
  position: relative;
`

export const SectionBody = styled.p`
  ${gwText.bodySmall};
  color: ${colors.dusk80};
  margin: 8px 0 24px 0;
`

export const SectionHeading = styled.h2`
  ${gwText.headingMedium};
  color: ${colors.midnight};
  margin-bottom: 0;
`

export const SectionSubHeading = styled.h3`
  ${gwText.headingSmallCaps};
  color: ${colors.midnight};
  margin: 40px 0 16px 0px;
`
export const StafferNameHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`

export const StafferEditOptions = styled.div`
  align-items: flex-end;
  display: flex;
  gap: 8px;
  justify-content: space-between;
`

export const Name = styled.h3`
  ${gwText.headingMediumLight};
  color: ${colors.midnight70};
  margin-bottom: 0;
`

export const ContactInfoContainer = styled.div`
  align-items: center;
  display: flex;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`

export const ContactInfo = styled.p`
  ${gwText.bodyDefault};
  color: ${colors.dusk};
  margin: 0 0 0 8px;
`
